import useWindowResize from "hooks/useWindowResize";
import { useAfricanYouth } from "context/africanYouth";
import { t } from "i18next";
import useAutocomplete from "hooks/useAutocomplete";
import { AutoCompleteStyles } from "utils/autoComplete";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ClearIcon from "assets/images/ClearIcon";
import { Country } from "types/country.types";
import { countries } from "utils/lists";
import { useMemo } from "react";
import Warning from "components/Warning";
/** @jsxImportSource @emotion/react */
import { theme } from "twin.macro";

const Filters = () => {
  const { country, mobileModalDropdown, setMobileModalDropdown } =
    useAfricanYouth();
  const { handleAutocompleteChange } = useAutocomplete();
  const { isDesktop } = useWindowResize();

  const countryList: Country[] = useMemo(() => {
    return [
      ...countries.filter(
        ({ name }) =>
          name === "Kenya" ||
          name === "Rwanda" ||
          name === "Ghana" ||
          name === "Ethiopia" ||
          name === "Nigeria"
      ),
      {
        iso3c: "",
        name: "More coming...",
        license: "Public domain",
        file_url: "",
        url: "",
        disabled: true,
      },
    ];
  }, []);

  const memoCountryInput = useMemo(
    () => (
      <Autocomplete
        readOnly={isDesktop ? false : true}
        clearIcon={<ClearIcon />}
        autoHighlight
        options={countryList}
        onChange={(_, value, reason) =>
          handleAutocompleteChange(value?.name!, reason, "education_section")
        }
        value={
          country.education_section
            ? { name: country.education_section }
            : (null as any)
        }
        tw="w-full"
        sx={AutoCompleteStyles}
        getOptionLabel={(option) => option.name}
        renderOption={(props: any, option: Country) => (
          <button
            disabled={option.disabled}
            {...props}
            style={{
              pointerEvents: option.disabled && "none",
              color: theme`colors.grey.700`,
              display: "flex",
              fontSize: "0.8rem",
              fontWeight: 700,
              gap: "0.65rem",
              width: "100%",
              textAlign: "left",
            }}>
            {option.file_url ? (
              <img
                loading="lazy"
                width="16"
                src={option.file_url}
                alt={option.name}
              />
            ) : (
              <span style={{ width: "1rem" }}></span>
            )}
            {option.name}
          </button>
        )}
        renderInput={(params: any) => {
          const activeCountryFlag = countries.find(
            ({ name }) => name === params.inputProps.value
          );

          return (
            <div
              tw="flex items-center relative"
              onClick={() =>
                isDesktop
                  ? undefined
                  : setMobileModalDropdown({
                      value: !mobileModalDropdown.isMobileModalDropdownOpen,
                      name: "mobileModalDropdown",
                      section: "countries/education_section",
                    })
              }>
              {activeCountryFlag && (
                <img
                  key={params.inputProps.value + "'s flag"}
                  loading="lazy"
                  width="24"
                  src={activeCountryFlag.file_url}
                  alt="Country flag"
                  tw="absolute left-[0.5rem] z-10"
                />
              )}
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
                sx={{
                  textAlign: "left",
                  paddingLeft: params.inputProps.value ? "2rem" : 0,
                  input: {
                    "&::placeholder": {
                      color: "#ADADAD !important",
                    },
                  },
                }}
                placeholder={t("type_or_select_a_region_placeholder")}
              />
            </div>
          );
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [country.education_section, isDesktop]
  );

  return (
    <article tw="md:w-full max-w-[19rem] md:max-w-none w-full">
      <label tw="text-grey-500 text-sm pb-[0.625rem] sm:block">
        {t("region")}
      </label>
      <div tw="flex gap-2 items-center">
        {memoCountryInput}
        <Warning countryName={country.education_section!} />
      </div>
    </article>
  );
};

export default Filters;
